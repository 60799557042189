import React from "react";
import { Router } from "@reach/router";
//import Layout from "../components/layout"
//import Profile from "../components/profile"
import Login from "./login";
import Reports from "../components/reports";
import Dataloggers from "../components/dataloggers";
import DataloggersManager from "../components/dataloggers-manager";
import UsersManager from "../components/users-manager";
import MeasuresManager from "../components/measures-manager";
import Dashboard from "./dashboard";
import Admin from "../components/admin";
import Profile from "../components/profile";
import Settings from "../components/settings";
import PrivateRoute from "../components/private-route";

import Layout from "../layouts/en";

import { setLanguagePath } from "../components/lang-service";

//const DashboardIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Dashboard {...props} /></Layout>);
const DashboardIntl = (props) => (<Layout {...props}><Dashboard {...props} /></Layout>);
const DataloggersIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Dataloggers {...props} /></Layout>);
const DataloggersManagerIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><DataloggersManager {...props} /></Layout>);
const MeasuresManagerIntl = (props) => (<Layout location={props.location || {pathname: props.path}}><MeasuresManager {...props} /></Layout>);
const UsersManagerIntl = (props) => (<Layout location={props.location || {pathname: props.path}}><UsersManager {...props} /></Layout>);
const AdminIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Admin {...props} /></Layout>);
const ProfileIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Profile {...props} /></Layout>);
const SettingsIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Settings {...props} /></Layout>);
const ReportsIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Reports {...props} /></Layout>);
const LoginIntl = (props) => (<Layout location={(props.location || {pathname: props.path})}><Login {...props} /></Layout>);

const App = (props) => {
  console.log("en.js", props);
  setLanguagePath('/en');
  return (
    <>
      <Router>
        <PrivateRoute path="/en" component={DashboardIntl} />
        <PrivateRoute path="/en/dashboard" component={DashboardIntl} />
        <PrivateRoute path="/en/dataloggers" component={DataloggersIntl} />
        <PrivateRoute path="/en/dataloggers-manager" component={DataloggersManagerIntl} />
        <PrivateRoute path="/en/measures-manager" component={MeasuresManagerIntl} />
        <PrivateRoute path="/en/users-manager" component={UsersManagerIntl} />
        <PrivateRoute path="/en/admin" component={AdminIntl} />
        <PrivateRoute path="/en/profile" component={ProfileIntl} />
        <PrivateRoute path="/en/settings" component={SettingsIntl} />
        <PrivateRoute path="/en/reports" component={ReportsIntl} />
        <LoginIntl path={"/en/login"} />
      </Router>
    </>
  );
};

export default App;

